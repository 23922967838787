<div class="sidebar">
  <div *ngIf="this.sidebarInfo" class="sidebar_title">
    <p class="title_side">
      <i class="cinema_icon"></i>
    <span>Actions to take now</span>
    </p>
    <button class="expand" (click)="toggleBar1()">
      <i [ngClass]="bar1 ? 'dropdown_up' : 'dropdown_down'"></i>
    </button>
  </div>
  <div *ngIf="this.sidebarInfo" class="expand_content"  [ngClass]="bar1 ? 'open' : ''">
    <ul class="actions_list">
      <li *ngIf="!this.sidebarInfo.isPITPassed">
        <div class="check">
        </div>
        <p>Take the <a routerLink="{{this.pitModalWindow}}" click="goToPage(this.pitModalWindow)">Psychometric intelligence test</a>
        </p>
      </li>
      <li *ngIf="!this.sidebarInfo.isSMRPassed">
        <div class="check">
        </div>
        <p>Take the <a (click)="handleOpenModal()">Success Metrics Rating test </a>
        </p>
      </li>
      <li *ngIf="isTL && !this.sidebarInfo.isTLHasOneTeam">
        <div class="check">
        </div>
        <p><a routerLink="{{this.createTeam}}" click="goToPage(this.createTeam)">Create your first team </a>
          and invite your team to the app
        </p>
      </li>
      <li *ngIf="isTL && isTrial">
        <div class="check">
        </div>
        <p>Free Trial: Put your best foot forward, confirm your subscription before you lose access in {{daysLeft}} days.
          <a routerLink="{{this.subsLink}}" click="goToPage(this.subsLink)">Buy subscription </a>
        </p>
      </li>
      <li *ngIf="isConsultant">
        <div class="check">
        </div>
        <p>Invite your clients
          <i>*ask them to specify yourself as a referral while their onboarding to receive your reward</i>
        </p>
      </li>
      <li>
        <div class="check">
        </div>
        <p><a routerLink="{{this.profileEdit}}" click="goToPage(this.profileEdit)">Complete your profile</a></p>
      </li>
      <!-- <li>
        <div class="check">
        </div>
        <p>Take the <a href="">Weekly Recap Rating test </a>
        </p>
      </li> -->
      <!-- <li>
        <div class="check">
        </div>
        <p>
          <a href="">Book a consultation</a>
            with our TEMA Consultant to find out your team areas of improvement, and how you can support your team on their growth.
        </p>
      </li> -->
    </ul>
  </div>
  <!-- ToDO -->
  <!-- <div class="sidebar_title person">
    <p class="title_side ">
      <i class="profile_blue_icon"></i>
      <span>Profile</span>
    </p>
    <div class="profile_part_right">
      <p class="progress">80% completed</p>
      <button class="expand" (click)="toggleBar2()">
        <i [ngClass]="bar2 ? 'dropdown_up' : 'dropdown_down'"></i></button>
    </div>
  </div>
  <div class="expand_content" [ngClass]="bar2 ? 'open' : ''">
    <ul>
      <li>
        <div class="check">
        </div>
        <p>Add your <a href="">title/team role</a></p>
      </li>
      <li>
        <div class="check">
        </div>
        <p>Add your <a href="">industry</a></p>
      </li>
    </ul>
  </div>-->
  <div class="sidebar_title info">
    <p class="title_side">
      <i class="info_icon"></i>
      <span>Must Read Articles</span>
    </p>
    <button class="expand" (click)="toggleBar3()">
      <i [ngClass]="bar3 ? 'dropdown_up' : 'dropdown_down'"></i></button>
  </div>
  <div class="expand_content" [ngClass]="bar3 ? 'open' : ''">
    <ul>
      <li>
        <p>
          "5 Things High-Performing Teams Do Differently" by Ron Friedman (Harvard Business Review) - <a  (click)="onNavigate(link1)">link</a>.</p>
      </li>
      <li>
        <p>"Collaborative leadership: what it is and how it can bring your teams together" (Workplace) - <a  (click)="onNavigate(link2)">link</a>.</p>
      </li>
        <li>
        <p>"Is The End Of Remote Work Jobs Approaching?" by Dr. Gleb Tsipursky (Forbes) - <a  (click)="onNavigate(link3)">link</a>.</p>
      </li>
    </ul>
  </div>
  <p class="notice">
    If you encounter any difficulty in using our portal, please e-mail <a href="mailto:support@temaresources.app">(support@temaresources.app)</a> us for assistance.<br>
Or please use <a href="https://docs.google.com/forms/d/e/1FAIpQLSeYVqOXs4iWmpn9DqotkdVh9sOg105ev2bfCJJlTYfQD2_KDw/viewform">this form</a> to leave your feedback to us.
  </p>
  <p class="regard">From TEMA with 💙</p>
</div>

<take-test-modal [openModal]="openModal" (close)="handleTakeTest()"/>
