import { Component, OnInit } from '@angular/core';
import {TokenStorageService} from "../../../shared/services/token.service";
import {RolesConstants} from "../../../shared/constants/roles-constants";
import {Router, ActivatedRoute} from "@angular/router";
import { SubscriptionPlan } from '../../models/subscription-plan';
import { RightSidebarService } from '../../services/right-sidebar-service';
import { RightSidebarInfo } from '../../models/right-sidebar-info';

@Component({
  selector: 'app-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})

export class RightSidebarComponent implements OnInit {
  bar1 = true;
  bar2 = false;
  bar3 = true;
  public isTL = false;
  public isConsultant = false;
  public pitModalWindow = "/pit/taketest";
  public smrModalWindow = "/smr/test";
  public subsLink ="/subscription";
  public createTeam = "/team/create";
  public sidebarInfo: RightSidebarInfo | null = null;
  public isTrial: boolean;
  public daysLeft: number;
  public link1 = "https://hbr.org/2021/10/5-things-high-performing-teams-do-differently";
  public link2 = "https://www.workplace.com/blog/collaborative-leadership";
  public link3 = "https://www.forbes.com/sites/glebtsipursky/2023/02/06/the-future-of-remote-work-is-here-to-stay/";
  public profileEdit = "/profile/edit";
  openModal: any;

  constructor(
    private router: Router,
    private sidebarService: RightSidebarService,
    private tokenStorageService: TokenStorageService) {
    this.isTL = tokenStorageService.getUserRoles() == RolesConstants.TEAM_LEADER;
    this.isConsultant = tokenStorageService.getUserRoles() == RolesConstants.CONSULTANT;
  }

  async ngOnInit() {
    this.sidebarInfo = await this.sidebarService.getSidebarData();
    this.isTrial =  this.sidebarInfo.currentPlan === SubscriptionPlan.Trial;
    this.daysLeft = Math.ceil(((new Date(this.sidebarInfo.expireIn).getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
  }

  toggleBar1() {
    this.bar1 = !this.bar1;
  }

  toggleBar2() {
    this.bar2 = !this.bar2;
  }

  toggleBar3() {
    this.bar3 = !this.bar3;
  }

  goToPage(pageName: string) {
    this.router.navigate([`${pageName}`]);
  }

  onNavigate(url: string){
    window.open(url, '_blank');
  }

  handleOpenModal() {
    this.openModal = true;
  }
  handleTakeTest() {
    this.openModal = false;
  }
}
