<div class="header">
  <div class="leftside" (click)="onLogoClick()">
    <img src="assets/logo.png" >
  </div>
  <div class="rightside" *ngIf="!!planName">
    <div class="trial_wrap desktop">
      <div class="plan_badge"  [ngStyle]="{'background-color': subPlan == SubscriptionPlan.Expert ? '#FBE4E4' : '#DEEFFF' }" (click)="onOpenPopup()"><i class="plan"></i>{{planName}}<span> plan</span>
      </div>
      <div class="trial" (click)="openTrialModal()" *ngIf="isShowDays">{{daysLeft}} days left</div>
    </div>
    <div class="account_avatar">
      <!-- <img src="/assets/images/userIcon.png" alt=""> -->
       <p class="username">{{username}}</p>
      <button class="expand" (click)="handleToggleOpts()">
        <i [ngClass]="openOpts ? 'dropdown_up' : 'dropdown_down'"></i>
      </button>
    </div>
    <div class="account_popup">
      <div class="head">
        <!-- <img src="/assets/images/userIcon.png" alt="" class="avatar"> -->
        <p class="username">{{username}}<br>
        <span>{{userRole}}</span><br>
        <span>{{email}}</span>
        </p>
      </div>
      <ul class="options">
        <li (click)="onProfileClick()"><i class="profile_grey_icon" ></i><span>Profile</span></li>
        <li (click)="onSubscriptionsClick()" *ngIf="!isConsultant"><i class="premium_icon"></i><span>Subscriptions</span></li>
        <li (click)="onNewsClick()"><i class="light_icon"></i><span>What's New</span></li>
        <li (click)="onLogoutClick()"><i class="logout_icon"></i><span>Logout</span></li>
        <li class="mobile">
              <div class="trial_wrap mobile">
            <div class="plan_badge" (click)="onSubscriptionClick()"><i class="plan"></i> {{planName}}<span> plan</span>
            </div>
            <div class="trial" (click)="onTrialClick()" *ngIf="isShowDays" [ngStyle]="{'background-color': getLabelColor(daysLeft)}">{{daysLeft}} days left</div>
          </div>
</li>
      </ul>
    </div>
  </div>
</div>
<!-- <app-modal>
  <h3 class="title">Already leaving?</h3>
  <p class="question">Are you sure that you want to log out ?</p>
  <div class="card_buttons"><button class="cancel">Keep using TEMA</button><button class="confirm">Sign out</button></div>
</app-modal> -->

<app-subscriptions-popup  [subscription]="planName" [popupOpen]="openPopup"  (popupClose)="onClosePopup()" [isTrial]="isShowDays"/>
<!-- ! trial ends -->
<app-modal [className]="trial" *ngIf="openTrial" (closeModal)="closeTrialModal()" (blur)="closeTrialModal()">
  <h2 class="modal_title">30 days trial ends soon</h2>
  <p>Buy a subscription to keep your access to our advanced tools and your data (what you're going to lose): all your data, metrics in SMR results, Pro customer support.</p>
  <p>Not sure which subscription to choose - book a 15 mins call with us (your needs).</p>
  <div class="card_buttons">
    <button class="cancel" (click)="closeTrialModal()"><a href="https://calendar.app.google/r3BgmWNn3XcJcqU6A" target="_blank">Need help?</a></button>
    <button class="confirm" (click)="goToSubscriptions()">Find my ideal plan</button>
  </div>
</app-modal>
